import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import useEffectOnce from '@rio-cloud/rio-uikit/useEffectOnce';
import { IntlProvider } from 'react-intl';
import { Outlet } from 'react-router-dom';
import { config } from '../../configuration/config';
import { useAppSelector } from '../../configuration/setup/hooks';
import { useWidgetListener } from '../../hooks/widgetHooks';
import { DefaultRedirect } from '../../routes/DefaultRedirect';
import { selectDisplayMessages, selectLocale } from '../../store/lang/langSelectors';
import { sendMessage } from '../../store/widget/widgetServices';
import { widgetActions } from '../../store/widget/widgetSlice';

export const AppLayout = () => {
    const userLocale = useAppSelector(selectLocale);
    const displayMessages = useAppSelector(selectDisplayMessages);
    useWidgetListener();
    useEffectOnce(() => {
        sendMessage(widgetActions.widgetLoaded());
    });

    return (
        <IntlProvider
            defaultLocale={config.login.defaultLocale}
            key={userLocale}
            locale={userLocale}
            messages={displayMessages}
        >
            <ApplicationLayout data-testid="app-layout">
                <NotificationsContainer />
                <Outlet />
                <DefaultRedirect />
            </ApplicationLayout>
        </IntlProvider>
    );
};
