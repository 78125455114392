import { createSelector } from 'reselect';

import { RootState } from '../../configuration/setup/store';

export const selectWidget = (state: RootState) => state.widget;
export const selectSelectedAssetId = createSelector(selectWidget, (state) => state.selectedAssetId);
export const selectWidgetId = createSelector(selectWidget, (state) => state.widgetId);
export const selectIsVisible = createSelector(selectWidget, (state) => state.isVisible);
export const selectMapContext = createSelector(selectWidget, (state) => state.mapContext);
export const selectSelectedPois = createSelector(selectWidget, (state) => state.selectedPois);
export const selectSelectedGeofences = createSelector(selectWidget, (state) => state.selectedGeofences);
