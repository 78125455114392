import { config } from '../configuration/config';
import { BatteryStatusInterval } from './types';

export const STORE_BASE_PATH = 'rangeWidget/';
export const DEFAULT_ROUTE = '/';

export const WINDOW_BOUNDING_BOX_DEGREES = 0.2;
export const VEHICLE_DATA_POLLING_IN_SECONDS = 20;

export const ISOLINE_TIME_DEFAULT_FORMATTED = '04:30';
export const ISOLINE_TIME_LIMIT_FORMATTED = '09:00';
export const ISOLINE_TIME_LIMIT_HOURS = 9;

export const Unit = {
    KWH: 'kWh',
    currency: '€',
    weight: 'kg',
    km: 'km',
    pct: '%',
    hour: 'h',
    temp: 'ºC',
};

export const DEFAULT_COLOR_CLASSES: BatteryStatusInterval[] = [
    { min: 21, max: 100, backgroundColor: 'bg-success', text: 'text-success text-bold', color: 'success' },
    { min: 11, max: 20, backgroundColor: 'bg-warning', text: 'text-warning text-bold', color: 'warning' },
    { min: 0, max: 10, backgroundColor: 'bg-danger', text: 'text-danger text-bold', color: 'danger' },
];

export const HereSettings = {
    apikey: config.backend.MAP_SERVICE_API_KEY,
};

export const MarketplaceSettings = {
    URL: config.backend.MARKETPLACE_SERVICE,
    productId: 'man-sku00000085',
    defaultLevel: '10',
};

export const SmartRouteWebApiService = {
    serviceName: 'smart-route-web-api',
    apiVersion: {
        v1: 'v1',
        latest: 'v1',
    },
    endpoints: {
        assetData: '/asset-data',
        route: '/route',
        isoline: '/isoline',
        assetSubscription: 'subscription/asset',
    },
};

export const API = {
    serviceName: 'smart-route-web-api',
    version: {
        v1: 'v1',
        latest: 'v1',
    },
    endpoints: {
        assetData: '/asset-data',
        route: '/route',
        accountSubscription: '/subscription/account',
        assetSubscription: 'subscription/asset',
        permittedAssets: '/permitted-assets',
        accountGroups: '/tags/groups',
        chargePoints: '/charging-station/list',
        chargeStation: '/charging-station/',
        chargeStationNearAddress: '/charging-station/nearest',
        chargeStationAutocomplete: '/charging-station/autocomplete',
        chargeStationOperators: '/charging-station/operators',
        isoline: '/isoline',
        vehicleProfiles: '/vehicle-profiles',
        featureToggles: '/feature-toggles',
    },
    parameters: {
        limit: 1000,
    },
};

export const BASE_URL = `${config.backend.BACKEND_DOMAIN}/${API.serviceName}/${API.version.latest}`;
