import { createSlice } from '@reduxjs/toolkit';

import { STORE_BASE_PATH } from '../../common/constants';
import { config, ConfigState } from '../../configuration/config';

const initialState: ConfigState = config;

const configSlice = createSlice({
    name: STORE_BASE_PATH + 'config',
    initialState,
    reducers: {},
});

export default configSlice.reducer;
