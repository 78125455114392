import { AssetId } from '../../../common/types';
import { trace } from '../../../configuration/setup/trace';

export const SELECT_POIS_EVENT = 'EVENT_SELECT_POIS';

export type SelectPoisType = AssetId[];

export const selectPoisHandler = (data: SelectPoisType) => {
    trace.debug('Selected Pois data:', data);
};
