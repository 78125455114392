import { trace } from '../../../configuration/setup/trace';
import { sendMessage } from '../widgetServices';
import { widgetActions } from '../widgetSlice';

export const CENTER_ACTIVE_ASSET_EVENT = 'EVENT_CENTER_ACTIVE_ASSET';

type CenterActiveAssetType = string;

export const centerActiveAssetHandler = (data: CenterActiveAssetType) => {
    trace.debug('Center Active Asset:', data);
};

export const centerActiveAsset = () => sendMessage(widgetActions.centerActiveAsset());
