import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API, STORE_BASE_PATH } from '../../common/constants';
import { config } from '../../configuration/config';
import { prepareHeaders } from '../tokenHandling/prepareHeaders';

export const facadeApi = createApi({
    reducerPath: `${STORE_BASE_PATH}facadeApi`,
    baseQuery: fetchBaseQuery({
        baseUrl: `${config.backend.BACKEND_DOMAIN}/${API.serviceName}`,
        prepareHeaders,
    }),
    endpoints: (builder) => ({}),
});
