import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { STORE_BASE_PATH } from '../../common/constants';

export interface UserProfile {
    sub?: string;
    azp?: string;
    account?: string;
    givenName?: string;
    familyName?: string;
    name?: string;
    locale?: string;
    email?: string;
}

export interface LoginState {
    hasUserSessionEverExpired: boolean;
    userProfile: UserProfile | null;
    userSessionExpired: boolean;
}

const initialState: LoginState = {
    hasUserSessionEverExpired: false,
    userProfile: null,
    userSessionExpired: false,
};

const loginSlice = createSlice({
    name: STORE_BASE_PATH + 'login',
    initialState,
    reducers: {
        userProfileObtained: (state, action: PayloadAction<UserProfile>) => {
            state.userProfile = action.payload;
        },
        userSessionExpired: (state) => {
            state.hasUserSessionEverExpired = true;
            state.userSessionExpired = true;
        },
        userSessionRenewed: (state) => {
            state.userSessionExpired = false;
        },
    },
});

export const { userProfileObtained, userSessionExpired, userSessionRenewed } = loginSlice.actions;

export default loginSlice.reducer;
