import { lazy } from 'react';
import { createHashRouter, createRoutesFromElements, Route } from 'react-router-dom';
import { FallbackError } from '../components/FallbackError/FallbackError';

import { DEFAULT_ROUTE } from '../common/constants';
import { SuspendedWithSpinner } from '../components/SuspendedWithSpinner/SuspendedWithSpinner';
import { AppLayout } from '../containers/AppLayout/AppLayout';

// Lazy load pages for better performance and automatically split the bundle accordingly
const RangeWidgetLazyloaded = lazy(() =>
    import('../pages/RangeWidget/RangeWidget').then(({ RangeWidget }) => ({ default: RangeWidget }))
);

export const routes = [DEFAULT_ROUTE];

export const router = createHashRouter(
    createRoutesFromElements(
        <Route path="/" element={<AppLayout />} errorElement={<FallbackError />}>
            <Route
                path={DEFAULT_ROUTE}
                element={
                    <SuspendedWithSpinner>
                        <RangeWidgetLazyloaded />
                    </SuspendedWithSpinner>
                }
            />
        </Route>
    )
);
