import { createSlice } from '@reduxjs/toolkit';

import { STORE_BASE_PATH } from '../../common/constants';

export interface AppState {
    sessionExpiredAcknowledged: boolean;
}

const initialState: AppState = {
    sessionExpiredAcknowledged: false,
};

export const appSlice = createSlice({
    name: STORE_BASE_PATH + 'app',
    initialState,
    reducers: {
        hideSessionExpiredDialog: (state) => {
            state.sessionExpiredAcknowledged = true;
        },
    },
});

export const { hideSessionExpiredDialog } = appSlice.actions;

export default appSlice.reducer;
