import { useEffect } from 'react';

import { trace } from '../configuration/setup/trace';
import { processWidgetAction } from '../store/widget/widgetServices';

export const useWidgetListener = () => {
    useEffect(() => {
        trace.debug('--widget listener activated--');
        const listenerCallback = (event: MessageEvent) => processWidgetAction(event);
        window.addEventListener('message', listenerCallback, false);
        return () => window.removeEventListener('message', listenerCallback, false);
    }, []);
};
